import { ref, watch, onMounted } from '@vue/composition-api'
import productRepository from '@/repository/productRepository'
// import userRepository from '@/repository/userRepository'
import store from '@/store'

export const getGroupingSettingsStorage = () => JSON.parse(localStorage.getItem('statProdGrpSettings', null)) || { groupingSettings: null, tasksSelected: [] }

export const setGroupingSettingsStorage = val => {
  localStorage.setItem('statProdGrpSettings', JSON.stringify(val))
}

export function productStatisticsList() {
  const gridOptions = ref({})
  const columnDefs = ref([])
  const rowData = ref([])
  const orderCount = ref(0)
  const orderTotal = ref(0)
  const defaultColDef = ref({
    flex: 1,
    resizable: true,
    sortable: false,
    cellClassRules: {
      'bg-success bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-1"',
      'bg-warning bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-2"',
      'bg-danger bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-3"',
      'bg-info bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-4"',
      'bg-secondary bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-5"',
    },
  })

  const searchQuery = ref('')
  const period = ref('')
  const groupings = ref(null)
  const tasksSelected = ref(null)
  const userOptions = ref([])
  const userId = ref(null)

  const groupingSettings = getGroupingSettingsStorage()
  groupings.value = groupingSettings.groupingSettings
  tasksSelected.value = groupingSettings.tasksSelected

  columnDefs.value = [
    {
      headerName: 'Код 1С',
      field: 'code',
      colSpan(params) {
        if (params.data.section) {
          return 4
        }
        return 1
      },
      cellClassRules: {
        'row-1 bg-success bg-lighten-5 ': 'data.section && data.section === "row-1"',
        'row-2 bg-warning bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-2"',
        'row-3 bg-danger bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-3"',
        'row-4 bg-info bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-4"',
        'row-5 bg-secondary bg-lighten-5 font-weight-bolder': 'data.section && data.section === "row-5"',
      },
    },
    { headerName: 'Наименование', field: 'name' },
    { headerName: 'Артикул', field: 'articul' },
    { headerName: 'Бренд', field: 'brand' },
    { headerName: 'Тип детали', field: 'type' },
    { headerName: 'Количество', field: 'count' },
    { headerName: 'Сумма', field: 'total' },
  ]

  function onShowLoading() {
    gridOptions.value.api.showLoadingOverlay()
  }

  function onHideLoading() {
    gridOptions.value.api.hideOverlay()
  }

  async function fetchItems() {
    try {
      onShowLoading()

      const { items, count, total } = await productRepository.statisticListAll({
        query: searchQuery.value ? searchQuery.value : null,
        period: period.value ? period.value.split(' — ') : null,
        groupings: (Object.keys(groupings.value || {}) || []).length > 0 ? groupings.value : null,
        // userId: userId.value ? userId.value : null,
        // tasksTypeId: tasksSelected.value ? tasksSelected.value : null,
      })
      rowData.value = items
      orderCount.value = count
      orderTotal.value = total
      onHideLoading()
    } catch (error) {
      rowData.value = []
      onHideLoading()
      store.commit('app/SET_ERROR', error)
    }
  }

  async function fetchUsers() {
    // const users = await userRepository.userListAll({
    //   sord: 'asc',
    //   sidx: 'lastName',
    // })
    userOptions.value = []
    // users.map(x => ({
    //   label: `${x.lastName ? x.lastName : ''} ${x.name ? x.name : ''}`,
    //   value: x.id,
    // }))
  }

  const changeFilter = filter => {
    const { groupingSettings: gs } = getGroupingSettingsStorage()
    setGroupingSettingsStorage({ tasksSelected: filter.tasksSelected, groupingSettings: gs })

    tasksSelected.value = filter.tasksSelected
  }

  watch([searchQuery, period, groupings, userId, tasksSelected], () => {
    fetchItems()
  })

  onMounted(() => {
    fetchItems().then()
    fetchUsers().then()
  })

  return {
    gridOptions,
    columnDefs,
    defaultColDef,
    rowData,
    orderCount,
    orderTotal,
    onShowLoading,
    onHideLoading,

    searchQuery,
    period,
    groupings,
    userOptions,
    userId,
    tasksSelected,

    changeFilter,
  }
}
