<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >

              <flat-pickr
                v-model="period"
                class="form-control  mr-1"
                placeholder="Период"
                :config="{ mode: 'range', locale: Russian, dateFormat: 'd.m.Y' }"
              />

            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Поиск..."
                />
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :value="userId"
                  :options="userOptions"
                  placeholder="Продавец"
                  class="w-100 ml-1"
                  :reduce="val => val.value"
                  @input="(val) => userId = val"
                />
                <!-- <task-filter
                  class="ml-1"
                  :tasks-selected="tasksSelected"
                  @change="changeFilter"
                /> -->
                <grouping-settings
                  class="ml-1"
                  :grouping-list="groupingList"
                  :get-grouping-settings-storage="getGroupingSettingsStorage"
                  :set-grouping-settings-storage="setGroupingSettingsStorage"
                  @update="groupings = $event"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="table-height">
          <ag-grid-vue
            id="myGrid"
            style="width: 100%; height: 100%;"
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            :default-col-def="defaultColDef"

            :column-defs="columnDefs"
            :row-data="rowData"
          />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput,
} from 'bootstrap-vue'
import { AgGridVue } from 'ag-grid-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Russian } from 'flatpickr/dist/l10n/ru'
import { productStatisticsList, getGroupingSettingsStorage, setGroupingSettingsStorage } from '@/application/productService'
import GroupingSettings from '@/components/GroupingSettings.vue'
// import TaskFilter from './components/TaskFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,

    AgGridVue,
    vSelect,
    flatPickr,
    GroupingSettings,
    // TaskFilter,
  },
  setup() {
    const gridHeight = 500
    const {
      gridOptions,
      columnDefs,
      defaultColDef,
      rowData,
      orderCount,
      orderTotal,
      searchQuery,
      period,
      groupings,
      userOptions,
      userId,
      tasksSelected,
      changeFilter,
    } = productStatisticsList()

    return {
      gridOptions,
      columnDefs,
      defaultColDef,
      rowData,
      orderCount,
      orderTotal,
      gridHeight,

      searchQuery,
      period,
      groupings,
      userOptions,
      userId,
      tasksSelected,
      changeFilter,

      Russian,
      getGroupingSettingsStorage,
      setGroupingSettingsStorage,

      groupingList: [
        {
          key: 'type', title: 'Тип товара', selected: false,
        },
        {
          key: 'orderCreateAt', title: 'Дата продажи', selected: false,
        },
        {
          key: 'taxNumber', title: 'Инн чека', selected: false,
        },
        {
          key: 'managerClient', title: 'Менеджер по продажам', selected: false,
        },
        {
          key: 'partnerName', title: 'Контрагент', selected: false,
        },
        {
          key: 'userId', title: 'Исполнитель', selected: false,
        },
        {
          key: 'actionId', title: 'Акции', selected: false,
        },
        {
          key: 'id', title: 'Товар', selected: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
  @import "~ag-grid-community/dist/styles/ag-grid.css";
  @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .table-height {
    height: calc( 100vh - calc( calc(2rem * 1) + 4.45rem + 6.5rem + 0rem + 0rem + 7.5rem ) );
  }
</style>

<style>
.row-1 {
  font-size: 16px;
}
.row-2 {
  font-size: 15px;
  padding-left: 30px !important;
}
.row-3 {
  font-size: 14px;
  padding-left: 40px !important;
}
.row-4 {
  padding-left: 50px !important;
}
.row-5 {
  font-size: 14px;
  padding-left: 60px !important;
}
.row-6 {
  padding-left: 70px !important;
}
</style>
