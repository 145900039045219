<template>
  <div>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="primary"
      @click="isTableRowsSettingsSidebarActive = true"
    >

      <div class="text-nowrap">
        <feather-icon
          icon="CodepenIcon"
          size="18"
          class="mr-50"
        />
        <span class="align-text-top">Группировка </span>
      </div>
    </b-button>

    <b-sidebar
      id="table-column-settings-sidebar"
      :visible="isTableRowsSettingsSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="shownSidebar"
      @change="(val) => isTableRowsSettingsSidebarActive = val"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Настройка группировки строк таблицы
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <div class="p-2">
          <draggable
            v-model="resultColumn"
            :move="onMoveCallback"
            tag="ul"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(item, index) in resultColumn"
              :key="index"
              tag="li"
            >
              <b-form-checkbox
                v-model="item.selected"
                :value="true"
                :unchecked-value="false"
              >
                {{ item.title }}
              </b-form-checkbox>
            </b-list-group-item>
          </draggable>

          <div class="px-2 py-1">
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="updateTableRows"
              >
                Применить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton, BSidebar, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import draggable from 'vuedraggable'

export default {
  components: {
    BButton,
    BSidebar,
    BListGroupItem,
    BFormCheckbox,

    draggable,
  },
  directives: {
    Ripple,
  },
  props: {
    getGroupingSettingsStorage: {
      type: Function,
      required: true,
    },
    setGroupingSettingsStorage: {
      type: Function,
      required: true,
    },
    groupingList: {
      type: Array,
      required: true,
    },
    notMovable: {
      type: String,
      default: 'id',
    },
  },
  setup(props, { emit }) {
    const isTableRowsSettingsSidebarActive = ref(false)
    const resultColumn = ref([])

    const shownSidebar = () => {
      const { groupingSettings } = props.getGroupingSettingsStorage()
      if (!groupingSettings) {
        resultColumn.value = [...props.groupingList]
        return
      }

      const arGroupingSettings = Object.keys(groupingSettings).map(key => ({ key, sort: groupingSettings[key] }))
      arGroupingSettings.sort((a, b) => a.sort - b.sort)

      const columns = []
      arGroupingSettings.filter(x => x.key !== props.notMovable).forEach(item => {
        const search = props.groupingList.find(x => x.key === item.key)
        if (search) {
          columns.push({ ...search, selected: true })
        }
      })

      if (arGroupingSettings.filter(x => x.key !== props.notMovable).length !== props.groupingList.length - 1) {
        props.groupingList.filter(x => !arGroupingSettings.map(item => String(item.key)).includes(x.key) && x.key !== props.notMovable).forEach(item => {
          columns.push({ ...item, selected: false })
        })
      }

      columns.push({ ...props.groupingList[props.groupingList.length - 1], selected: !!groupingSettings.taskId })

      resultColumn.value = columns
    }

    const updateTableRows = () => {
      const groupingSettings = {}
      resultColumn.value.filter(x => x.selected).map(x => x.key).forEach((key, i) => {
        groupingSettings[key] = i + 1
      })
      const { tasksSelected } = props.getGroupingSettingsStorage()
      props.setGroupingSettingsStorage({ tasksSelected, groupingSettings })
      emit('update', groupingSettings)
      isTableRowsSettingsSidebarActive.value = false
    }

    const onMoveCallback = ({ draggedContext }) => draggedContext.element.key !== props.notMovable

    return {
      isTableRowsSettingsSidebarActive,
      resultColumn,

      shownSidebar,
      updateTableRows,
      onMoveCallback,
    }
  },
}
</script>
